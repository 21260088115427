/* home.css */
body {
    font-family: 'Roboto', sans-serif;
}

/* Apply Cavolini font to h1 element */
.cavolini-font {
    font-family: 'Cavolini', sans-serif;
}
/* Ensure the slideshow container fills the entire viewport */
.slideshow-container {
    position: relative;
    width: 100vw; /* Set width to 100% of viewport width */
    height: 100vh; /* Set height to 100% of viewport height */
    overflow: hidden; /* Hide any overflow to prevent scrollbars */
    max-width: 100%; /* Maximum width of 100% */
    margin: auto; /* Center the container horizontally */
    background-color: #000; /* Set background color to black */
  }
  
  /* Style for each slide */
  .slide {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex; /* Use flexbox to layout image and text side by side */
  }
  
  /* Style for the image */
  .slide img {
    flex: 1; /* Let the image take up remaining space */
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Style for the text container */
  .text {
    width: 30%; /* Set a fixed width for the text container */
    color: white;
    font-size: 15px;
    background-color: rgba(0, 0, 0, 0.849); /* Set background color to dark with transparency */
    padding: 20px; /* Adjust padding as needed */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    
  }
  
  /* Style for the previous and next buttons */
  .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    z-index: 1;
    transition: background-color 0.3s;
  }
  
  /* Hover effect for previous and next buttons */
  .prev:hover, .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  /* Positioning for the previous button */
  .prev {
    left: 20px; /* Adjust left position as needed */
  }
  
  /* Positioning for the next button */
  .next {
    right: 20px; /* Adjust right position as needed */
  }
  
  /* Style for the voice command button */
  .voice-command {
    position: absolute;
    bottom: 20px;
    left: 20px;
    cursor: pointer;
    font-size: 16px;
    background-color: rgba(0, 0, 0, 0.555);
    color: white;
    border: none;
    padding: 10px;
    transition: background-color 0.3s;
  }
  
  /* Hover effect for voice command button */
  .voice-command:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  